import axios from "axios"; 
import { layer } from "@layui/layui-vue";
import { getToken } from '@/utils/auth'
import router from '../router'

const service = axios.create({
    //baseURL: 'http://192.168.2.3:8073', // url = base url + request url
    timeout: 30000,// request timeout
    // withCredentials:true
})
// 请求拦截
service.interceptors.request.use( 
    (config: any) => { 
        config.headers['X-Token'] = getToken()

        return config
    },
    (error: any) => {
        return Promise.reject(error)
    }
)
 
// 响应拦截
service.interceptors.response.use(
    (response: any) => {
        const res = response.data
        if (res.code==0) {
            return response.data
        } else if(res.code==1006){
            layer.msg(res.msg, { icon : 2, time: 1000}) 
            router.push({ path: '/login' }) 
            return "";
        }else {
            layer.msg(res.msg, { icon : 2, time: 1000}) 
            return "";
        }
    },
    (error: any) => {
        if (error.response) {
            switch (error.response.status) {
                case 500:
                    layer.msg("服务器错误，请稍后重试", { icon : 2, time: 1000}) 
                    return "";
                    
                default:
                    layer.msg("服务器错误，请稍后重试", { icon : 2, time: 1000})  
                    return "";
            }
        }
    }
)
 
export default service