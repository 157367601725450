
import loginVue from '../login/login.vue';
<template>
    <lay-layout class="example">
        <lay-side style="width: auto;flex: 0 0 auto;align-items: normal;"> 
            <lay-menu v-model:selected-key="selectedKey" v-model:tree="isTree" v-model:open-keys="openKeys6"   @change-selected-Key="changeSelectedKey"
                :collapse="collapse" style="padding-top: 0px">
                <div style="line-height: 60px;text-align: center; background: rgb(119 85 85); color: black;">logo</div>


                <lay-menu-item id="1">
                    <template #icon>
                        <lay-icon type="layui-icon-chart"></lay-icon>
                    </template>
                    <template #title>
                        首页
                    </template>
                </lay-menu-item>
                <lay-sub-menu id="2">
                    <template #icon><lay-icon type="layui-icon-read"></lay-icon></template>
                    <template #title>
                        楼凤
                    </template>
                    <lay-menu-item id="3">
                        <template #title> 楼凤列表</template>
                    </lay-menu-item>
                    <lay-menu-item id="4">
                        <template #title>审核管理</template>
                    </lay-menu-item>
                </lay-sub-menu>

                <lay-sub-menu id="5">
                    <template #icon><lay-icon type="layui-icon-dollar"></lay-icon></template>
                    <template #title>
                        财务
                    </template>
                    <lay-menu-item id="6">
                        <template #title> 明细列表</template>
                    </lay-menu-item>
                    <lay-menu-item id="7">
                        <template #title>支付配置</template>
                    </lay-menu-item>
                </lay-sub-menu>

                <lay-sub-menu id="8">
                    <template #icon><lay-icon type="layui-icon-user"></lay-icon></template>
                    <template #title>
                        用户
                    </template>
                    <lay-menu-item id="9">
                        <template #title> 用户列表</template>
                    </lay-menu-item>
                    <lay-menu-item id="10">
                        <template #title>后台用户</template>
                    </lay-menu-item>
                </lay-sub-menu>
                <lay-menu-item id="14">
                    <template #icon>
                        <lay-icon type="layui-icon-question"></lay-icon>
                    </template>
                    <template #title>
                        投诉列表
                    </template>
                </lay-menu-item>

                <lay-sub-menu id="11">
                    <template #icon><lay-icon type="layui-icon-set-sm"></lay-icon></template>
                    <template #title>
                        系统
                    </template>
                    <lay-menu-item id="12">
                        <template #title> 系统设置</template>
                    </lay-menu-item>
                </lay-sub-menu>
            </lay-menu>
        </lay-side>
        <lay-layout>
            <lay-header>
                <div style="position:absolute;">

                    <lay-icon :type="collapse ? 'layui-icon-spread-left' : 'layui-icon-shrink-right'" size="lg"
                        @click="menuSize(collapse)"></lay-icon>
                </div>
                <div style="text-align: right; margin-right: 10;">
                    <lay-dropdown trigger="hover" updateAtScroll>

                        <lay-button style="border-color: transparent;">admin <lay-icon type="layui-icon-down"
                                style="margin-right: 10;"></lay-icon></lay-button>
                        <template #content>
                            <lay-dropdown-menu>
                            
                                <lay-dropdown-menu-item @click="changePassword">修改密码</lay-dropdown-menu-item>
                                <lay-dropdown-menu-item @click="quit">退出</lay-dropdown-menu-item>
                            </lay-dropdown-menu>
                        </template>
                    </lay-dropdown>

                </div>

            </lay-header>
            <lay-body>
                <chartView v-if="viewsType == 1"></chartView>  
                <loufengListView v-else-if="viewsType == 3"></loufengListView>  
                <loufengProcessView v-else-if="viewsType == 4"></loufengProcessView>  
                <payDetailsView v-else-if="viewsType == 6"></payDetailsView>  
                <payConfigView v-else-if="viewsType == 7"></payConfigView>   
                <userListView v-else-if="viewsType == 9"></userListView>  
                <adminUserLustView v-else-if="viewsType == 10"></adminUserLustView>  
                <setupView v-else-if="viewsType == 12"></setupView>  
                <complaint v-else-if="viewsType == 14"></complaint>  
                <changePasswordView v-else-if="viewsType == 0"></changePasswordView>   

            </lay-body>

        </lay-layout>
    </lay-layout>
</template>
<script lang="ts">
import { ref } from 'vue'
import router from '../../router'
import chartView from '../chart/index.vue'
import loufengListView from '../loufeng/index.vue'
import loufengProcessView from '../loufeng/process.vue'
import payDetailsView from '../finance/payDetails.vue'
import payConfigView from '../finance/payConfig.vue'
import adminUserLustView from '../user/adminUserLust.vue'
import userListView from '../user/userList.vue'
import setupView from '../setup/index.vue'
import changePasswordView from '../changePassword/index.vue'
import complaint from '../complaint/index.vue'

export default {
    components: {
        chartView,loufengListView,loufengProcessView,payDetailsView,payConfigView,adminUserLustView,userListView,setupView,
        changePasswordView,complaint
    },
    setup() {
        const viewsType=ref(1);
        const selectedKey = ref("1")
        const openKeys6 = ref(["7"])
        const collapse = ref(true)
        const isTree = ref(true)

        const menuSize = (iscollapse: any) => {

            collapse.value = !iscollapse
        }

      
        const changePassword=()=>{
            viewsType.value = 0; 
             
        }

        const changeSelectedKey = (val:number) => {  //切换模板
            viewsType.value = val;
        }
        
        const quit=()=>{

            router.push({ path: '/login' }) 

        }


        return {
            selectedKey,
            openKeys6,
            collapse,
            isTree,
            menuSize, 
            viewsType,
            changeSelectedKey,
            changePassword,
            quit
        }
    }
}
</script>

<style>
.example .layui-footer,
.example .layui-header {
    line-height: 60px;
    text-align: center;
    background: #fefefefe;
    color: black;
}

.example .layui-side {
    display: flex;
    background: #77c38c;
    align-items: center;
    justify-content: center;
    color: white;
    min-height: 100vh;
}

.example .layui-body {
    padding: 5px;
    background: #F6F6F6;
    align-items: center;
    justify-content: center;
    color: black; 
}
</style>
